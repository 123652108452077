import { FEATURE } from 'api/src/common/enums'

import { routes, navigate } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import OnboardingMembers from 'src/components/Onboarding/OnboardingMembers'
import OnboardingPageHeader from 'src/components/Onboarding/OnboardingPageHeader'
import useFeature from 'src/lib/hooks/useFeature'

const OnboardingMembersPage = () => {
  // Get Feature Flag Status
  const onboardingFeatureFlag = useFeature(FEATURE.ONBOARDING)

  // Navigate away when permission denied
  if (!onboardingFeatureFlag) {
    navigate(routes.home())
  }

  return (
    <>
      <Metadata title="Setup - Members" description="Setup - Members page" />
      <div className="relative">
        <OnboardingPageHeader
          currentPage={{
            name: 'Members',
            url: routes.onboardingMembers(),
          }}
        />
        <OnboardingMembers />
      </div>
    </>
  )
}

export default OnboardingMembersPage
